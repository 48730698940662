<template>
  <div id="settlement-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Settlements </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- shop filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="shopFilterModel"
            placeholder="Select Shop"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilterModel"
            placeholder="Select Status"
            :items="statusFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <!-- Dates filter-->
        <v-col
          cols="12"
          sm="4"
        >
          <v-menu
            ref="menuDateRangeFilter"
            v-model="menuDateRangeFilterModel"
            :return-value.sync="datesFilter"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            hide-details
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Dates Range"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="datesFilter"
              range
              no-title
              scrollable
            >
              <v-btn
                block
                outlined
                small
                @click="$refs.menuDateRangeFilter.save(datesFilter)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn
          ref="btnApplyFilters"
          color="primary"
          class="me-3 mb-4"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          type="reset"
          class="me-3 mb-4"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>
      </v-card-text>

      <!-- table -->
      <v-data-table
        ref="settlementsTable"
        :headers="tableColumns"
        :items="settlementItems"
        :search="searchQuery"
        :options.sync="options"
        :server-items-length="settlementItemsLength"
        :items-per-page="10"
        :loading="loading"
      >
        <!-- UID -->
        <template #[`item.settlementCode`]="{ item }">
          <div class="d-flex align-left">
            <a
              href="#"
              @click="viewSettlement(item.awsKey)"
            >{{
              item.settlementCode
            }}</a>
          </div>
        </template>

        <!-- Date From -->
        <template #[`item.createdAt`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{
              formatDate(item.createdAt, { dateStyle: "short" })
            }}</span>
          </div>
        </template>

        <!-- Shop -->
        <template #[`item.shop`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize font-weight-semibold text--primary">{{
              item.shop
            }}</span>
          </div>
        </template>

        <!-- Date From -->
        <template #[`item.fromDate`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{
              formatDate(item.fromDate, { dateStyle: "short" })
            }}</span>
          </div>
        </template>

        <!-- Date To -->
        <template #[`item.toDate`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{
              formatDate(item.toDate, { dateStyle: "short" })
            }}</span>
          </div>
        </template>

        <!-- Opening Balance -->
        <template #[`item.openingBalance`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize text--primary align-right">$ {{
              formatCurrency(item.openingBalance)
            }}</span>
          </div>
        </template>

        <!-- Closing Balance -->
        <template #[`item.closingBalance`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize text--primary align-right">$ {{
              formatCurrency(item.closingBalance)
            }}</span>
          </div>
        </template>

        <!-- Amount -->
        <template #[`item.balanceToBeSettled`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize text--primary align-right">$ {{
              formatCurrency(item.balanceToBeSettled)
            }}</span>
          </div>
        </template>

        <!-- Status -->
        <template #[`item.withdrawalStatusCode`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.withdrawalStatusCode)"
            :class="`${resolveStatusVariant(item.withdrawalStatusCode)}--text`"
            class="v-chip-light-bg text-capitalize align-center"
          >
            {{ item.withdrawalStatusCode }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon @click="viewSettlement(item.awsKey)">
            {{ icons.mdiDownloadBoxOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- snackbar -->
    <v-snackbar
      v-model="isSnackbarVisible"
      vertical
      multi-line
      :light="$vuetify.theme.dark"
    >
      {{ snackbarMessage }}

      <template #action="{ attrs }">
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click="isSnackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// sidebar
import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiCalendar,
  mdiPublish,
  mdiPublishOff,
  mdiDownloadBoxOutline,
} from '@mdi/js'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import store from '@/store'
import settlementStoreModule from '../settlementStoreModule'
import useSettlementList from './useSettlementList'
import settlementStatus from '@/constants/settlement-status'

export default {
  setup() {
    const STORE_MODULE_NAME = 'finance-settlements'
    const isSnackbarVisible = ref(false)
    const snackbarMessage = ref('')

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, settlementStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const {
      settlementItems,
      settlementItemsLength,
      tableColumns,
      searchQuery,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      datesFilter,
      menuDateRangeFilterModel,
      datesRangeSelected,
      options,
      loading,
      fetchSettlements,
      viewSettlement,
      applyFilters,
      resetFilters,
      fetchConfig,
      resolveStatusVariant,
    } = useSettlementList()

    const dateRangeText = computed(() => datesFilter.value.join(' ~ '))

    onMounted(() => {
      fetchConfig()
    })

    return {
      isSnackbarVisible,
      snackbarMessage,
      settlementItems,
      tableColumns,
      searchQuery,
      settlementStatus,
      shopFilterModel,
      shopFilterItems,
      statusFilterModel,
      statusFilterItems,
      datesFilter,
      dateRangeText,
      menuDateRangeFilterModel,
      datesRangeSelected,
      settlementItemsLength,
      loading,
      options,
      avatarText,
      resolveStatusVariant,
      fetchSettlements,
      viewSettlement,
      applyFilters,
      resetFilters,
      fetchConfig,
      formatDate,
      formatCurrency,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCalendar,
        mdiPublish,
        mdiPublishOff,
        mdiDownloadBoxOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import "@core/preset/preset/finance/settlement.scss";
</style>
