import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import withdrawalStatus from '@/constants/withdrawal-status'

export default function useSettlementList() {
  const tableColumns = [
    { text: 'UID', value: 'settlementCode', sortable: false },
    { text: 'CREATED ON', value: 'createdAt' },
    { text: 'SHOP', value: 'shopName' },
    { text: 'FROM DATE', value: 'fromDate', sortable: false },
    { text: 'TO DATE', value: 'toDate', sortable: false },
    {
      text: 'CURRENCY', value: 'currencyCode', sortable: false, align: 'center',
    },
    {
      text: 'AMOUNT', value: 'balanceToBeSettled', sortable: false, align: 'right',
    },
    {
      text: 'STATUS', value: 'withdrawalStatusCode', sortable: false, align: 'center',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')

  const shopFilterModel = ref(null)
  const shopFilterItems = ref([])

  const statusFilterModel = ref(null)
  const statusFilterItems = ref([])

  const aMonthAgo = new Date()
  aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)
  const datesFilter = ref([aMonthAgo.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)])

  const datesRangeSelected = ref([])

  const settlementItems = ref([])
  const settlementItemsLength = ref(0)

  const loading = ref(false)

  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })

  const menuDateRangeFilterModel = ref(false)

  // fetch data
  const fetchSettlements = () => {
    loading.value = true

    store
      .dispatch('finance-settlements/fetchSettlements', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilterModel.value,
        shopId: shopFilterModel.value,
        periodType: 'CUSTOM',
        from: datesFilter.value[0],
        to: datesFilter.value[1],
      })
      .then(response => {
        const { records, count } = response.data
        settlementItems.value = records
        settlementItemsLength.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    fetchSettlements()
  })

  const applyFilters = () => {
    fetchSettlements()
  }

  const resetFilters = () => {
    searchQuery.value = ''
    shopFilterModel.value = null
    statusFilterModel.value = null
    datesFilter.value = [aMonthAgo.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)]
    datesRangeSelected.value = []
    settlementItemsLength.value = 0
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('finance-settlements/fetchSettlementListConfig')
      .then(response => {
        const config = response.data
        shopFilterItems.value = config.shops
        statusFilterItems.value = config.withdrawalStatus
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const viewSettlement = key => {
    store
      .dispatch('finance-settlements/getPresignedUrl', { key })
      .then(response => {
        const url = response.data
        window.open(url)
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = status => {
    if (status === withdrawalStatus.PENDING) return 'secondary'
    if (status === withdrawalStatus.COMPLETED) return 'success'
    if (status === withdrawalStatus.PROCESSING) return 'default'
    if (status === withdrawalStatus.CANCELLED) return 'error'

    return 'primary'
  }

  return {
    settlementItems,
    settlementItemsLength,
    tableColumns,
    searchQuery,
    shopFilterItems,
    shopFilterModel,
    statusFilterItems,
    statusFilterModel,
    datesFilter,
    datesRangeSelected,
    menuDateRangeFilterModel,
    loading,
    options,
    applyFilters,
    resetFilters,
    fetchSettlements,
    viewSettlement,
    fetchConfig,
    resolveStatusVariant,
  }
}
