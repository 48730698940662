var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"settlement-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Settlements ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"placeholder":"Select Shop","items":_vm.shopFilterItems,"item-text":"text","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.shopFilterModel),callback:function ($$v) {_vm.shopFilterModel=$$v},expression:"shopFilterModel"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"placeholder":"Select Status","items":_vm.statusFilterItems,"item-text":"text","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.statusFilterModel),callback:function ($$v) {_vm.statusFilterModel=$$v},expression:"statusFilterModel"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"menuDateRangeFilter",attrs:{"return-value":_vm.datesFilter,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","hide-details":""},on:{"update:returnValue":function($event){_vm.datesFilter=$event},"update:return-value":function($event){_vm.datesFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dates Range","prepend-icon":_vm.icons.mdiCalendar,"readonly":"","hide-details":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateRangeFilterModel),callback:function ($$v) {_vm.menuDateRangeFilterModel=$$v},expression:"menuDateRangeFilterModel"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.datesFilter),callback:function ($$v) {_vm.datesFilter=$$v},expression:"datesFilter"}},[_c('v-btn',{attrs:{"block":"","outlined":"","small":""},on:{"click":function($event){return _vm.$refs.menuDateRangeFilter.save(_vm.datesFilter)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-btn',{ref:"btnApplyFilters",staticClass:"me-3 mb-4",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.applyFilters.apply(null, arguments)}}},[_vm._v(" Apply Filters ")]),_c('v-btn',{staticClass:"me-3 mb-4",attrs:{"color":"secondary","outlined":"","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetFilters.apply(null, arguments)}}},[_vm._v(" Reset ")])],1),_c('v-data-table',{ref:"settlementsTable",attrs:{"headers":_vm.tableColumns,"items":_vm.settlementItems,"search":_vm.searchQuery,"options":_vm.options,"server-items-length":_vm.settlementItemsLength,"items-per-page":10,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.settlementCode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-left"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewSettlement(item.awsKey)}}},[_vm._v(_vm._s(item.settlementCode))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(item.createdAt, { dateStyle: "short" })))])])]}},{key:"item.shop",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.shop))])])]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(item.fromDate, { dateStyle: "short" })))])])]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(item.toDate, { dateStyle: "short" })))])])]}},{key:"item.openingBalance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize text--primary align-right"},[_vm._v("$ "+_vm._s(_vm.formatCurrency(item.openingBalance)))])])]}},{key:"item.closingBalance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize text--primary align-right"},[_vm._v("$ "+_vm._s(_vm.formatCurrency(item.closingBalance)))])])]}},{key:"item.balanceToBeSettled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize text--primary align-right"},[_vm._v("$ "+_vm._s(_vm.formatCurrency(item.balanceToBeSettled)))])])]}},{key:"item.withdrawalStatusCode",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg text-capitalize align-center",class:((_vm.resolveStatusVariant(item.withdrawalStatusCode)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.withdrawalStatusCode)}},[_vm._v(" "+_vm._s(item.withdrawalStatusCode)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.viewSettlement(item.awsKey)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadBoxOutline)+" ")])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"vertical":"","multi-line":"","light":_vm.$vuetify.theme.dark},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"error","text":""},on:{"click":function($event){_vm.isSnackbarVisible = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.isSnackbarVisible),callback:function ($$v) {_vm.isSnackbarVisible=$$v},expression:"isSnackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }